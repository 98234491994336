import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"

const handleClick = event => {
  event.persist();
  event.preventDefault();
  console.log("clicked", event);
  var win = window.open("https://arcoinnovation.square.site/product/wi-fi-garage-door-controller-kit/1?cs=true", '_blank');
  win.focus();
}


const GetStarted = () => (
  <Section id="pricing">
  <StyledSection>
    <GetStartedContainer>
      <GetStartedTitle>Do not wait, get yours now !</GetStartedTitle>
      <TryItButton onClick={handleClick}>Where to buy?</TryItButton>
    </GetStartedContainer>
  </StyledSection>
  </Section>
)

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const RegPrice = styled.h2`
  margin: 0 auto 15px;
  text-align: center;
`

const PromoPrice = styled.h2`
  margin: 0 auto 32px;
  text-align: center;
  color: red;
`

const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Subtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
  padding-top: 16px;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
`
