import React from "react"
import styled from "styled-components"
import London from "../../images/london.png"
import Product1 from "../../images/product1.png"
import App1 from "../../images/app1.png"
import Install1 from "../../images/Install1.png"

import { Link } from "gatsby"

import { Section, Container } from "../global"


function Features(props) {
    console.log("Props: ", props);
    return (
    <Section id="product">
    <StyledContainer>
      <SectionTitle>About us</SectionTitle>
      <FeaturesGrid>
      <FeatureItem>
        <img src={London} alt="Product" style={{width: "100%"}}/>  
          <FeatureTitle></FeatureTitle>
          <FeatureText></FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Arco Innovation</FeatureTitle>
          <FeatureText>
          We are a start-up company located in London, Ontario, a small city in Canada also called the forest city.
          <br></br>
          <br></br>
         Our founders have over 15 years of experience in engineering and commercialization of new products in Fortune 500 companies.
         <br></br>
         <br></br>
         We're proud to offer the highest quality electronics products in the market for the internet of things. Our products are proudly designed and assembled in Canada.
         <br></br>
         <br></br>
         <br></br>
        To learn more please visit: <a href="https://www.arcoinnovation.com">www.arcoinnovation.com</a>
          </FeatureText>
        </FeatureItem>
        
       

         </FeaturesGrid>
        
           
    </StyledContainer>
  </Section>
)}

export default Features

const StyledContainer = styled(Container)`
margin: 80px auto;`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  text-align: center;
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: justify;
`


