import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Sub_Navigation from "../components/common/navigation/sub_navigation"
import About from "../components/sections/about"
import GetStarted from "../components/sections/getstarted"





const AboutPage = () => (
    <Layout>
    <SEO title="Instructions" />
    <Sub_Navigation />
    <About />
  </Layout>
)


export default AboutPage